.windowContainer {
    min-width: 400px;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    padding-left: 1em;
}

.closeButtonContainer {
    position: absolute;
    right: 0;
    top: 0;
}

.closeButton {
    background-color: white;
    color: gray;
    padding: 0 0 0 1ex;
    border: none;
    border-radius: 0.8em;
}
