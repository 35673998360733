/*.logoContainer {*/
  /*position: relative;*/
/*}*/

/*.globalLogoWrapper {*/
  /*min-height: 130px;*/
/*}*/

.image {
  display: inline-block;
  width: 150px;
  height: 75px;
  background-color: lightgray;
}

.user {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}

.searchBar {
  display: inline-block;
  width: calc(100% - 150px - 150px - 0.5em - 0.5em);
  vertical-align: center;
  margin: 0 0.5em 0 0.5em;
}
