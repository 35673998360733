.label {
  display: inline-block;
  width: 25ex;
  padding: 1ex 0 1ex 0;
}

.mediaItem {
  padding: 0 0 1ex 0;
  /*width: 100%;*/
}

.mediaName {
  display: inline-block;
  width: 25ex;
}

.mediaId {
  display: inline-block;
  width: 15ex;
}

.mediaAction {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.mediaList {
  font-size: 0.7em;
}