.newProject label {
  width: 17ex;
  display: inline-block;
  margin: 1ex 1ex 1ex 0;
}


label.fileUpload input[type="file"] {
  position:absolute;
  top: -1000px;
}
label.fileUpload span {
  color: lightgray;
}
.fileUpload {
  height: 2em;
  line-height: 2em;
  border: lightgray solid thin;
  border-radius: 0.6em;
  padding: 0 0.6em 0 0.6em;
  margin: 2px;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.fileUpload:hover {
  background: #CCC;
}
.fileUpload:active {
  background: #CCF;
}


/* Customize the label (the container) */
.container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /*font-size: 22px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
//vertical-align: middle;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: inline-block;
  position: relative;
  height: 0.8em;
  width: 0.8em;
  border: lightgray solid thin;
  border-radius: 0.5em;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  //background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 0.22em;
  top: 0.09em;
  width: 0.2em;
  height: 0.35em;
  /*border: solid white;*/
  border: solid black;
  border-width: 0 0.1em 0.1em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45degbackground);
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.top {
  vertical-align: top;
}
