

.Input_inputRoot__aZOAj {
    clear: both;
    overflow: hidden;
    padding-bottom: 0.5em;
}

.Input_label__30U5S {
    width: 90px;
    display: block;
    /*float: left;*/
    line-height: 20px;
    padding: 5px 0 0 0;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
    color: darkgray;
}

.Input_inputWrapper__3PtQF {
    position: relative;
    display: block;
    padding-right: 20px;
    margin-top: 0;
    overflow: hidden;
}

.Input_input__3X7qo {
    font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 1.2em;
    min-height: 30px;
    margin-bottom: 1px;
    padding: 7px 8px;
    border: 1px solid #e0e0e0;
    color: #656565;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    border-radius: 0.8em;
    /*border-radius: 4px;*/
    background-clip: padding-box;
}

select.Input_input__3X7qo {
    height: 38px;
}

.Input_input__3X7qo:focus,
.Input_input__3X7qo:focus + .Input_charCounter__2P9Kj {
    border-color: #3875D7;
    outline: 0;
}

.Input_inputDisabled__1tLbx {
    opacity: 0.3;
    cursor: not-allowed;
}

.Input_inputInvalid__bc7KJ {
    border-color: #B94A48;
}

.Input_error__OXJK7 {
    position: relative;
    background-color: #D32F2F;
    color: #FFFFFF;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 4px;
    background-clip: padding-box;
}
.Input_error__OXJK7:before {
    content: '';
    display: block;
    position: absolute;
    border: 7px outset transparent;
}

.Input_errorBelow__l9KJ5 {
    display: inline-block;
    margin: 10px 0 0 0;
}

.Input_errorBelow__l9KJ5:before {
    left: 22px;
    top: -13px;
    border-bottom: 7px solid #D32F2F;
}

.Input_validationIcon__3uTV7 {
    position: absolute;
    right: 0;
    top: 0.7em;
}

.Input_validationIcon-asterisk__Hab8Y {
    color: darkgray;
}

.Input_validationIcon-times__L02Yo {
    color: #D6514B;
}

.Input_validationIcon-check__5_aE1 {
    color: #5CB85C;
}

.Input_textarea__2PiJE {
    display: block;
}

.Input_limitedTextarea__2WImJ {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
    box-sizing: border-box;
    height: 6em;
    border-radius: 4px 4px 0 0;
    background-clip: padding-box;
    scroll-padding: 50px 10px 10px 50px;
}

.Input_charCounter__2P9Kj {
    position: relative;
    float: right;
    text-align: right;
    background-color: #e6e6e6;
    color: #808080;
    margin: -1px 0 0;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}
.Input_charCounter__2P9Kj div {
    padding: 5px 10px 4px 0;
}

.Input_radioButton__2q6jZ {
    min-width: 100px;
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 5px;
}

.Input_fileUploader__1OVGv {
    position: relative;
    background-color: #f2f2f2;
    border-radius: 8px;
    border: 2px dashed #ccc;
    background-clip: border-box;
    background-clip: initial;
    overflow: hidden;
    padding: 30px 2%;
    text-align: center;
}

.Input_fileUploader__1OVGv input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.Input_fileUploader__1OVGv > p {
    margin-bottom: 1em;
    font-size: 14px;
    color: dimgray;
    /*color: clrGray;*/
}

.Input_fileUploaderButton__3TRy7 {
    margin: 10px 0 0;
}

.FormButtons_root__Wrcpm {
    /*padding: 30px 0 0 0;*/
    overflow: hidden;
    /*border-top: 1px solid clrBorder;*/
    margin: 5px 0 0 0;
    /*text-align: right;*/
    text-align: center;
}

.FormButtons_topMargin__3ZnfX {
    margin-top: 30px;
}

.FormButtons_root__Wrcpm > * {
    margin-left: 12px;
}

.FormButtons_root__Wrcpm > *:first-child {
    margin-left: 0;
}

.Button_button__1LyBY {
    font-size: 14px;
    line-height: 25px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 0.8em;
    background-clip: padding-box;
    padding: 4px 10px;
    margin: 0 0 10px 5px;
    display: inline-block;
    -webkit-transition: all 0.25 ease-in-out;
    transition: all 0.25 ease-in-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 2px solid black;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    word-spacing: normal;
}

.Button_button__1LyBY:not(.Button_disabled__BZ06W):hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px 0 inset, rgba(0, 0, 0, 0.1) 0 0 0 100px inset !important;
}

.Button_button__1LyBY.Button_small__2q2jS {
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 3px;
}

.Button_disabled__BZ06W {
    opacity: 0.5;
    cursor: not-allowed;
}

.Button_button__1LyBY .Button_icon__31DUn {
    margin: 0 7px 0 0;
}

.Button_bordered__3jDnR {
    background: transparent;
    border-width: 1px;
    border-style: solid;
}

.Button_button-primary__3KM72 {
    background: #e8e8e8;
    color: #4D4D4D;
}

.Button_button-gray__1ROdJ {
    background: #ccc;
    color: #4D4D4D;
}
.Button_button-gray__1ROdJ.Button_bordered__3jDnR {
    background: transparent;
    box-shadow: inset 0 0 0 1px #d8d8d8;
    border: none;
}
.Button_button-warning__tQ9sp {
    background: #dd8524;
}

.user {

}
label {
  display: block;
}
h1 {
  text-align: center;
  font-weight: bold;
}

h1 span.count {
  color: gray;
  font-size: 0.6em;
  font-weight: normal;
}

.project {
  border-color: lightgray;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 0.7em 0 0.7em 0;
}

.name {
  font-size: 1.2em;
  font-weight: bold;
}

.baseInfo {
  display: inline-block;
  width: 70%;
  font-size: 0.8em;
}

.pid {
  display: inline-block;
  width: 30%;
  vertical-align: top;
  font-size: 0.8em;
}
.newProject label {
  width: 17ex;
  display: inline-block;
  margin: 1ex 1ex 1ex 0;
}


label.fileUpload input[type="file"] {
  position:absolute;
  top: -1000px;
}
label.fileUpload span {
  color: lightgray;
}
.fileUpload {
  height: 2em;
  line-height: 2em;
  border: lightgray solid thin;
  border-radius: 0.6em;
  padding: 0 0.6em 0 0.6em;
  margin: 2px;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.fileUpload:hover {
  background: #CCC;
}
.fileUpload:active {
  background: #CCF;
}


/* Customize the label (the container) */
.container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /*font-size: 22px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
//vertical-align: middle;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: inline-block;
  position: relative;
  height: 0.8em;
  width: 0.8em;
  border: lightgray solid thin;
  border-radius: 0.5em;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  //background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 0.22em;
  top: 0.09em;
  width: 0.2em;
  height: 0.35em;
  /*border: solid white;*/
  border: solid black;
  border-width: 0 0.1em 0.1em 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45degbackground);
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.top {
  vertical-align: top;
}

.label {
  display: inline-block;
  width: 25ex;
  padding: 1ex 0 1ex 0;
}

.mediaItem {
  padding: 0 0 1ex 0;
  /*width: 100%;*/
}

.mediaName {
  display: inline-block;
  width: 25ex;
}

.mediaId {
  display: inline-block;
  width: 15ex;
}

.mediaAction {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.mediaList {
  font-size: 0.7em;
}

/*div {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,*/
    /*sans-serif;*/
  /*font-size: 1rem;*/
  /*line-height: 1.5;*/
/*}*/

/*button:disabled {*/
  /*opacity: 0.5;*/
/*}*/

/*.container {*/
  /*display: grid;*/
  /*grid-template-areas:*/
          /*"header header"*/
          /*"nav main"*/
          /*"footer footer";*/
  /*grid-template-rows: 40px 1fr 20px;*/
  /*grid-template-columns: min-content 1fr;*/

/*}*/
/*header {*/
  /*grid-column: 1 / 3;*/
  /*!*background: #455A64;*!*/
  /*!*color: white;*!*/
  /*!*padding: 4rem;*!*/
  /*text-align: center;*/
/*}*/
/*nav {*/
  /*grid-column: 1;*/
  /*white-space: nowrap;*/
  /*!*background: #37474F;*!*/
/*}*/
/*nav ul {*/
  /*padding: 1em 1em 1em 1em;*/
/*}*/
/*main {*/
  /*grid-column: 2;*/
  /*!*padding-bottom: 40rem;*!*/
/*}*/
/*footer {*/
  /*grid-column: 1 / 3;*/
  /*text-align: center;*/
  /*!*background: #607D8B;*!*/
  /*!*padding: 5rem 1rem;*!*/
/*}*/
/*.logoContainer {*/
  /*position: relative;*/
/*}*/

/*.globalLogoWrapper {*/
  /*min-height: 130px;*/
/*}*/

.Header_image__2DQau {
  display: inline-block;
  width: 150px;
  height: 75px;
  background-color: lightgray;
}

.Header_user__1FyYu {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}

.Header_searchBar__2HIoy {
  display: inline-block;
  width: calc(100% - 150px - 150px - 0.5em - 0.5em);
  vertical-align: center;
  margin: 0 0.5em 0 0.5em;
}


.ListContainer_item__3odCH {
    border-bottom: 1px solid lightgray;
    padding: 0.5em 0 0.5em 0.5em;
}
.ListContainer_item__3odCH:hover {
    background-color: rgba(0,0,0,0.05)
}

.ListContainer_list__1y0JD {
    list-style-type: none;
    border-top: 1px solid lightgray;
    padding-left: 1ex;
}

.SeriesShortView_container__1r9q0 {
  display: block;
  /*border: 1px solid darkgray;*/
}

.SeriesShortView_image__3SWQg {
  display: inline-block;
  width: 80px;
  height: 120px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.SeriesShortView_dataBlock__3x9BR {
  /*display: block;*/
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 80px - 0.5em);
  padding-right: 8px;
}

.SeriesShortView_wrap__JNGdX {
  overflow-wrap: break-word;
}

.SeriesShortView_details__18frb {
  font-size: smaller;
}

.WindowContainer_windowContainer__3_qMO {
    min-width: 400px;
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    padding-left: 1em;
}

.WindowContainer_closeButtonContainer__1RBLY {
    position: absolute;
    right: 0;
    top: 0;
}

.WindowContainer_closeButton___YAbY {
    background-color: white;
    color: gray;
    padding: 0 0 0 1ex;
    border: none;
    border-radius: 0.8em;
}

.Series_seriesData__1l5dE {
  margin-bottom: 0.5em
}

.Series_image__2aDnu {
  display: inline-block;
  width: 104px;
  height: 156px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.Series_fieldsBlock__2-KzR {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.Series_fieldsRow__3TJy1 + .Series_fieldsRow__3TJy1 {
  margin-top: 0.5em;
}

.Series_fieldName__eehpV {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.Series_fieldValue__dXgx2 {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
  white-space: pre-wrap;
}

.SeasonList_season__zOk7C {
  border-bottom: 1px solid darkgray;
}

.SeasonList_seasonList__17Ty5 {
  list-style-type: none;
  border-top: 1px solid lightgray;
  padding-left: 0;
}

.Season_seasonData__1oO1g {
  margin-bottom: 0.5em
}

.Season_image__19LAe {
  display: inline-block;
  width: 104px;
  height: 156px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.Season_fieldsBlock__2vDXx {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.Season_fieldsRow__1VMT2 + .Season_fieldsRow__1VMT2 {
  margin-top: 0.5em;
}

.Season_fieldName__mD7An {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.Season_fieldValue__b910c {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
}

.EpisodeList_episode__5jlAJ {
  border-bottom: 1px solid darkgray;
}

.EpisodeList_episodeList__9mqQo {
  list-style-type: none;
  border-top: 1px solid lightgray;
  padding-left: 0;
}

.Episode_seasonData__11Inz {
  margin-bottom: 0.5em
}

.Episode_image__2uoIa {
  display: inline-block;
  width: 104px;
  height: 156px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.Episode_fieldsBlock__27tbg {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.Episode_fieldsRow__3Zvr_ + .Episode_fieldsRow__3Zvr_ {
  margin-top: 0.5em;
}

.Episode_fieldName__21QW8 {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.Episode_fieldValue__1-54g {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
}

.LinkList_episode__17fg5 {
  border-bottom: 1px solid darkgray;
}

.LinkList_episodeList__1Jnox {
  list-style-type: none;
  border-top: 1px solid lightgray;
  padding-left: 0;
}

/*@value clrBorder, fsTitle from '../../vars.css';*/

.Page_contentHeader__1TE_m {
    overflow: hidden;
    /*border-bottom: 1px solid black;*/
    /*margin: 40px 0;*/
}

.Page_title__acyUR {
    margin: 0;
    /*height: 55px;*/
    /*line-height: 55px;*/
    /*font-size: fsTitle;*/
}

.Page_container__18V3s {
    border-left: 1px solid darkgray;
    margin-left: 1ex;
    padding-left: 0.5ex;
}
.Video_seasonData__ExJ7l {
  margin-bottom: 0.5em
}

.Video_image__FOUcU {
  display: inline-block;
  width: 104px;
  height: 156px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.Video_fieldsBlock__fcJuV {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.Video_fieldsRow__2HB24 + .Video_fieldsRow__2HB24 {
  margin-top: 0.5em;
}

.Video_fieldName__1ZjgU {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.Video_fieldValue__xe87o {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  height: 100%;
}

button:disabled {
  opacity: 0.5;
}

.appContainer {
  display: grid;
  grid-template-areas:
          "header header"
          "nav main"
          "footer footer";
  grid-template-rows: 80px 1fr 20px;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
}
header {
  grid-column: 1 / 3;
  /*background: #455A64;*/
  /*color: white;*/
  /*padding: 4rem;*/
  text-align: center;
}
nav {
  grid-column: 1;
  white-space: nowrap;
  /*background: #37474F;*/
}
nav ul {
  padding: 1em 1em 1em 1em;
}
main {
  grid-column: 2;
  /*padding-bottom: 40rem;*/
}
footer {
  grid-column: 1 / 3;
  text-align: center;
  /*background: #607D8B;*/
  /*padding: 5rem 1rem;*/
}
h1 {
  text-align: left;
  font-size: x-large;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
h2 {
}
h3 {
  font-size: large;
  margin: 0 0 5px 0;
}

a {
  color: black;
  text-decoration: none;
}

.react-router-modal__modal {
  /*position: fixed;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*background-color: white;*/
  border-radius: 0.8em;
  border: 2px solid black;
}
.react-router-modal__backdrop {
  background-color: rgba(0,0,0,0.15)
}

