h1 {
  text-align: center;
  font-weight: bold;
}

h1 span.count {
  color: gray;
  font-size: 0.6em;
  font-weight: normal;
}

.project {
  border-color: lightgray;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 0.7em 0 0.7em 0;
}

.name {
  font-size: 1.2em;
  font-weight: bold;
}

.baseInfo {
  display: inline-block;
  width: 70%;
  font-size: 0.8em;
}

.pid {
  display: inline-block;
  width: 30%;
  vertical-align: top;
  font-size: 0.8em;
}