@value bigButtonPadding: 4px 25px;
@value clrGrayBtn: #ccc;
@value clrWarningBtn: #dd8524;

.button {
    font-size: 14px;
    line-height: 25px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 0.8em;
    background-clip: padding-box;
    padding: 4px 10px;
    margin: 0 0 10px 5px;
    display: inline-block;
    transition: all 0.25 ease-in-out;
    user-select: none;
    border: 2px solid black;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    word-spacing: normal;
}

.button:not(.disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px 0 inset, rgba(0, 0, 0, 0.1) 0 0 0 100px inset !important;
}

.button.small {
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 3px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button .icon {
    margin: 0 7px 0 0;
}

.bordered {
    background: transparent;
    border-width: 1px;
    border-style: solid;
}

.button-primary {
    background: #e8e8e8;
    color: #4D4D4D;
}

.button-gray {
    background: clrGrayBtn;
    color: #4D4D4D;
}
.button-gray.bordered {
    background: transparent;
    box-shadow: inset 0 0 0 1px #d8d8d8;
    border: none;
}
.button-warning {
    background: clrWarningBtn;
}
