
/*div {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,*/
    /*sans-serif;*/
  /*font-size: 1rem;*/
  /*line-height: 1.5;*/
/*}*/

/*button:disabled {*/
  /*opacity: 0.5;*/
/*}*/

/*.container {*/
  /*display: grid;*/
  /*grid-template-areas:*/
          /*"header header"*/
          /*"nav main"*/
          /*"footer footer";*/
  /*grid-template-rows: 40px 1fr 20px;*/
  /*grid-template-columns: min-content 1fr;*/

/*}*/
/*header {*/
  /*grid-column: 1 / 3;*/
  /*!*background: #455A64;*!*/
  /*!*color: white;*!*/
  /*!*padding: 4rem;*!*/
  /*text-align: center;*/
/*}*/
/*nav {*/
  /*grid-column: 1;*/
  /*white-space: nowrap;*/
  /*!*background: #37474F;*!*/
/*}*/
/*nav ul {*/
  /*padding: 1em 1em 1em 1em;*/
/*}*/
/*main {*/
  /*grid-column: 2;*/
  /*!*padding-bottom: 40rem;*!*/
/*}*/
/*footer {*/
  /*grid-column: 1 / 3;*/
  /*text-align: center;*/
  /*!*background: #607D8B;*!*/
  /*!*padding: 5rem 1rem;*!*/
/*}*/