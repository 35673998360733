@value clrText, clrWhite, clrBorderLight, clrBorder, clrError, clrSuccess, clrLightGray, clrGray from '../../constants/colors.css';

.inputRoot {
    clear: both;
    overflow: hidden;
    padding-bottom: 0.5em;
}

.label {
    width: 90px;
    display: block;
    /*float: left;*/
    line-height: 20px;
    padding: 5px 0 0 0;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
    color: darkgray;
}

.inputWrapper {
    position: relative;
    display: block;
    padding-right: 20px;
    margin-top: 0;
    overflow: hidden;
}

.input {
    font: 14px/1.5 Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 1.2em;
    min-height: 30px;
    margin-bottom: 1px;
    padding: 7px 8px;
    border: 1px solid clrBorderLight;
    color: clrText;
    background-color: clrWhite;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    border-radius: 0.8em;
    /*border-radius: 4px;*/
    background-clip: padding-box;
}

select.input {
    height: 38px;
}

.input:focus,
.input:focus + .charCounter {
    border-color: #3875D7;
    outline: 0;
}

.inputDisabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.inputInvalid {
    border-color: #B94A48;
}

.error {
    position: relative;
    background-color: #D32F2F;
    color: #FFFFFF;
    font-size: 12px;
    padding: 4px 10px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
}
.error:before {
    content: '';
    display: block;
    position: absolute;
    border: 7px outset transparent;
}

.errorBelow {
    display: inline-block;
    margin: 10px 0 0 0;
}

.errorBelow:before {
    left: 22px;
    top: -13px;
    border-bottom: 7px solid #D32F2F;
}

.validationIcon {
    position: absolute;
    right: 0;
    top: 0.7em;
}

.validationIcon-asterisk {
    color: darkgray;
}

.validationIcon-times {
    color: clrError;
}

.validationIcon-check {
    color: clrSuccess;
}

.textarea {
    display: block;
}

.limitedTextarea {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
    box-sizing: border-box;
    height: 6em;
    border-radius: 4px 4px 0 0;
    background-clip: padding-box;
    scroll-padding: 50px 10px 10px 50px;
}

.charCounter {
    position: relative;
    float: right;
    text-align: right;
    background-color: #e6e6e6;
    color: #808080;
    margin: -1px 0 0;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}
.charCounter div {
    padding: 5px 10px 4px 0;
}

.radioButton {
    min-width: 100px;
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 5px;
}

.fileUploader {
    position: relative;
    background-color: clrLightGray;
    border-radius: 8px;
    border: 2px dashed clrBorder;
    background-clip: initial;
    overflow: hidden;
    padding: 30px 2%;
    text-align: center;
}

.fileUploader input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.fileUploader > p {
    margin-bottom: 1em;
    font-size: 14px;
    color: dimgray;
    /*color: clrGray;*/
}

.fileUploaderButton {
    margin: 10px 0 0;
}
