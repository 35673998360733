html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  height: 100%;
}

button:disabled {
  opacity: 0.5;
}

.appContainer {
  display: grid;
  grid-template-areas:
          "header header"
          "nav main"
          "footer footer";
  grid-template-rows: 80px 1fr 20px;
  grid-template-columns: min-content 1fr;
}
header {
  grid-column: 1 / 3;
  /*background: #455A64;*/
  /*color: white;*/
  /*padding: 4rem;*/
  text-align: center;
}
nav {
  grid-column: 1;
  white-space: nowrap;
  /*background: #37474F;*/
}
nav ul {
  padding: 1em 1em 1em 1em;
}
main {
  grid-column: 2;
  /*padding-bottom: 40rem;*/
}
footer {
  grid-column: 1 / 3;
  text-align: center;
  /*background: #607D8B;*/
  /*padding: 5rem 1rem;*/
}
h1 {
  text-align: left;
  font-size: x-large;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
h2 {
}
h3 {
  font-size: large;
  margin: 0 0 5px 0;
}

a {
  color: black;
  text-decoration: none;
}

.react-router-modal__modal {
  /*position: fixed;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*background-color: white;*/
  border-radius: 0.8em;
  border: 2px solid black;
}
.react-router-modal__backdrop {
  background-color: rgba(0,0,0,0.15)
}
