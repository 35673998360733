/*@value clrBorder, fsTitle from '../../vars.css';*/

.contentHeader {
    overflow: hidden;
    /*border-bottom: 1px solid black;*/
    /*margin: 40px 0;*/
}

.title {
    margin: 0;
    /*height: 55px;*/
    /*line-height: 55px;*/
    /*font-size: fsTitle;*/
}

.container {
    border-left: 1px solid darkgray;
    margin-left: 1ex;
    padding-left: 0.5ex;
}