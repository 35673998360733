.seasonData {
  margin-bottom: 0.5em
}

.image {
  display: inline-block;
  width: 104px;
  height: 156px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.fieldsBlock {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 140px - 0.5em);
}

.fieldsRow + .fieldsRow {
  margin-top: 0.5em;
}

.fieldName {
  display: inline-block;
  vertical-align: top;
  width: calc(100px);
}
.fieldValue {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 100px - 0.5em);
  margin-left: 0.5em;
}
