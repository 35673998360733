
.item {
    border-bottom: 1px solid lightgray;
    padding: 0.5em 0 0.5em 0.5em;
}
.item:hover {
    background-color: rgba(0,0,0,0.05)
}

.list {
    list-style-type: none;
    border-top: 1px solid lightgray;
    padding-left: 1ex;
}
