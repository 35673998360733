.container {
  display: block;
  /*border: 1px solid darkgray;*/
}

.image {
  display: inline-block;
  width: 80px;
  height: 120px;
  background-color: lightgray;
  margin-right: 0.5em;
}

.dataBlock {
  /*display: block;*/
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 80px - 0.5em);
  padding-right: 8px;
}

.wrap {
  overflow-wrap: break-word;
}

.details {
  font-size: smaller;
}
